
import './App.css';
import Aa from './aaa'
import { useEffect } from 'react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function App() {
  useEffect(() => {
    setTimeout(() => 
    history.push('https://elfatih.sch.id/')
    , 15 * 1000)
  
  }, [])

  return (
    <div className="App">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        {/* <p>Situs Sekolah Madani saat ini terintegrasi dengan website elfatih.sch.id. Anda akan dialihkan otomatis ke situs tersebut dalam 30 detik atau klik tombol di bawah ini</p> */}
        <Aa />
        <div style={{ margin: '20px 0px' }}>
          <a
            style={{ color: 'white', cursor: 'pointer', fontSize: '20px', textDecoration: 'none' }} href='https://elfatih.sch.id/'>
            <button
              style={{ border: 'none', padding: '10px 16px', fontSize: '20px', backgroundColor: '#1fc337', color: 'white', borderRadius: '6px' }}
            >
              Elfatih.sch.id
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
