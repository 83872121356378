import * as React from "react";

function aaa(props) {
  return (
    <svg
      width="900px"
      height="auto"
      viewBox="0 0 10655.25 6028.19"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>
          {
            "@font-face{font-family:&quot;Asap&quot;;font-variant:normal;font-style:normal;font-weight:400;src:url(#prefix__FontID0) format(svg)}.prefix__fil5{fill:#fefefe}.prefix__fil2{fill:#1fc337;fill-rule:nonzero}.prefix__fil0,.prefix__fil3,.prefix__fil4{fill:#2f2e41;fill-rule:nonzero}.prefix__fil0,.prefix__fil3{fill:#3f3d56}.prefix__fil3{fill:#ccc}.prefix__fnt0{font-weight:400;font-size:309.24px;font-family:&apos;Asap&apos;}"
          }
        </style>
        <font
          id="prefix__FontID0"
          horizAdvX={612}
          fontVariant="normal"
          fontStyle="normal"
          fontWeight={400}
          fillRule="nonzero"
        >
          <fontFace fontFamily="Asap">
            <fontFaceSrc>
              <fontFaceName name="Asap Regular" />
            </fontFaceSrc>
          </fontFace>
          <glyph
            unicode="."
            horizAdvX={197}
            d="M90.995-2.002c-26.658 0-40.002 7.337-40.002 21.996v66.003c0 14.674 13.344 21.996 40.002 21.996h16.998c26.673 0 40.002-7.322 40.002-21.996V19.994c0-14.66-13.329-21.996-40.002-21.996H90.995z"
          />
          <glyph
            unicode={0}
            horizAdvX={534}
            d="M266.994-6.007c-41.99 0-79.99 12.84-114 38.504-33.995 25.665-61.326 65.163-81.992 118.51-20.666 53.33-31 119.655-31 198.987 0 79.333 10.334 145.672 31 199.003 20.666 53.332 47.997 92.83 81.992 118.51 34.01 25.664 72.01 38.488 114 38.488 42.005 0 80.341-12.824 115.009-38.489 34.668-25.68 62.335-65.178 83-118.509 20.667-53.331 31-119.67 31-199.003 0-79.332-10.333-145.657-31-198.988-20.665-53.347-48.332-92.845-83-118.509S308.999-6.007 266.994-6.007zm0 72.01c26 0 49.846 9.66 71.506 28.997 21.66 19.336 39.162 50.167 52.506 92.493C404.336 229.834 411 284.007 411 349.994c0 66.004-6.665 120.175-19.994 162.501-13.344 42.341-30.846 73.172-52.506 92.509C316.84 624.34 292.995 634 266.994 634c-25.328 0-48.822-9.508-70.497-28.508-21.66-19-38.994-49.831-52.002-92.493-12.993-42.662-19.49-97.003-19.49-163.006 0-65.988 6.497-120.328 19.49-162.99 13.008-42.678 30.342-73.509 52.002-92.509 21.675-19 45.169-28.492 70.497-28.492z"
          />
          <glyph
            unicode={3}
            horizAdvX={534}
            d="M246.007-6.007c-37.343 0-71.338 3.011-102.001 9.003-30.678 6.007-54.845 13.344-72.5 22.011-17.67 8.667-26.505 17.319-26.505 25.986 0 9.34 4.326 20.009 12.993 32.008 8.667 12 16.004 18.006 22.011 18.006 4.662 0 14.002-2.675 27.988-8.01 42.678-17.99 86.012-26.994 130.004-26.994 37.343 0 70.665 10.67 99.999 31.993 29.333 21.339 44.007 56.664 44.007 106.006 0 47.997-12.84 81.992-38.505 102.001-25.664 19.994-62.839 29.99-111.493 29.99h-33.001c-6.665 0-11.663 2.34-15.01 7.001-3.333 4.678-5 12.336-5 23.005v11.006c0 10.67 1.667 18.327 5 22.99 3.347 4.677 8.345 7 15.01 7H228c31.992 0 57.84 7.17 77.498 21.507 19.672 14.338 33.842 31.504 42.509 51.497 8.652 20.01 12.993 39.33 12.993 57.994 0 24.67-9.003 46.835-26.995 66.508C316 624.173 285.337 634 242.002 634c-28.003 0-51.16-2.506-69.503-7.504-18.328-4.999-35.83-11.159-52.491-18.496-11.342-4.662-18.68-7-22.011-7-5.335 0-12.168 6.664-20.499 19.993-8.33 13.344-12.503 24.013-12.503 32.008 0 14.001 21.002 26.337 63.007 36.991 42.005 10.67 80.662 16.004 116.003 16.004 61.326 0 110.332-14.995 147.002-45 36.655-29.991 54.998-73.998 54.998-131.992 0-32.665-9.844-61.005-29.501-85.003-19.673-23.999-44.176-41.669-73.509-52.995v-4.005c33.338-8.667 62.671-29.333 88-61.999 25.343-32.665 38-73.34 38-122.01 0-47.996-11.16-87.663-33.491-118.998-22.333-31.32-50.504-54.156-84.5-68.494-34.01-14.338-68.999-21.507-104.996-21.507z"
          />
          <glyph
            unicode="A"
            horizAdvX={612}
            d="M54.998-2.002c-28.66 0-42.999 5.67-42.999 16.997 0 3.332.994 8.01 2.996 14.002l237.003 657.006c4.005 10.67 17.334 16.004 40.003 16.004h28.996c23.999 0 37.343-5.335 40.003-16.004l237.003-658c2.002-4.678 2.996-9.003 2.996-13.008 0-11.327-14.338-16.998-42.999-16.998h-7.994c-23.341 0-36.67 5.335-40.002 16.004l-59.003 164H156.998l-57-164C96.666 3.331 83.337-2.003 59.996-2.003h-4.999zM424.008 254L318.996 551.994c-3.333 10.669-7.322 30.005-12 58.008h-2.002c-5.335-26.673-10.333-46.01-14.995-58.008L185.002 254h239.006z"
          />
          <glyph
            unicode="S"
            horizAdvX={500}
            d="M244.004-6.007C204.002-6.007 166.995-1.33 133 7.994c-33.995 9.34-59.66 20.666-76.994 34.01-12.671 9.325-19 17.656-19 24.993 0 8.01 4.494 19 13.498 33.001C59.507 114 66.66 121.001 71.995 121.001c2.675 0 9.676-3.332 21.003-9.997 20.666-11.342 42.83-21.002 66.507-28.997 23.662-8.01 51.834-12.014 84.5-12.014 38.657 0 70.16 10.837 94.495 32.512 24.335 21.66 36.502 50.168 36.502 85.493 0 24.67-6.665 45.169-20.009 61.509-13.33 16.325-29.486 29.333-48.486 38.994-19 9.66-46.514 21.506-82.512 35.493-38.657 15.347-69.335 29.333-91.988 42.005-22.67 12.671-42.005 31.503-58.01 56.495C57.995 447.502 50 480.335 50 520.994c0 35.998 9.172 68.006 27.499 96.01 18.343 28.003 44.328 49.83 78.003 65.498 33.658 15.668 72.5 23.494 116.507 23.494 22.653 0 47.996-3.164 76-9.492 27.988-6.343 49.663-14.842 64.994-25.512 13.33-7.994 19.994-16.66 19.994-25.985 0-7.337-4.158-18.007-12.489-32.008-8.346-14.002-14.842-21.003-19.504-21.003-3.332 0-8.163 1.667-14.506 4.999-6.328 3.332-10.501 5.67-12.504 7-33.322 17.335-67.99 26.001-103.988 26.001-38.672 0-69.84-9.996-93.502-29.99-23.662-20.009-35.508-47.676-35.508-83 0-22.67 5.839-41.012 17.502-54.998 11.663-14.002 25.007-24.503 40.002-31.504 14.995-7 41.164-17.838 78.507-32.497 39.987-15.347 72.668-30.174 97.996-44.512 25.328-14.323 46.835-34.989 64.505-61.998 17.655-26.995 26.49-61.494 26.49-103.5 0-59.995-19.993-107.335-59.996-142.002C366 11.327 311.995-6.007 244.004-6.007z"
          />
          <glyph
            unicode="a"
            horizAdvX={544}
            d="M220.006-6.007c-51.344 0-92.677 12.672-124.012 38-31.32 25.343-46.988 62.67-46.988 112.013 0 52.659 15.82 92.157 47.492 118.494 31.672 26.337 70.498 43.334 116.507 50.993 45.995 7.673 102.001 11.846 167.99 12.503V373c0 24.671-8.668 44.833-26.001 60.501-17.334 15.668-45.322 23.494-83.995 23.494-29.333 0-54.997-3.5-76.993-10.501-22.012-6.986-40.34-13.987-54.998-20.987-14.674-7.001-22.669-10.502-24.014-10.502-6.664 0-14.154 6.665-22.5 19.994-8.33 13.33-12.488 23.341-12.488 30.006 0 8.667 8.835 17.991 26.49 28.003 17.67 9.997 41.179 18.496 70.512 25.496 29.333 7.001 59.66 10.502 90.995 10.502 61.999 0 110.332-13.834 145-41.5 34.667-27.668 52.001-66.172 52.001-115.499v-224.01c0-43.993 2.002-75.496 5.992-94.497 4.005-19 6.007-29.5 6.007-31.503 0-7.995-6.343-13.834-19-17.502C445.331.84 433.67-.994 423-.994c-9.34 0-16.172 5.167-20.498 15.5-4.341 10.333-7.673 21.155-9.997 32.497A5822.797 5822.797 0 00387.995 69C342.673 19 286.667-6.007 220.006-6.007zm3.99 73.004c32.665 0 60.836 6.007 84.498 18.006 23.678 12 47.844 28.997 72.5 50.993v118.005c-50-.672-90.827-2.675-122.499-6.007-31.656-3.332-59.491-12.657-83.49-27.988-23.998-15.347-35.997-39.009-35.997-71.002 0-26.673 7.153-47.003 21.491-61.005 14.338-14.001 35.509-21.002 63.497-21.002z"
          />
          <glyph
            unicode="b"
            horizAdvX={569}
            d="M320.998-6.007c-57.994 0-111.004 25.007-159.001 75.006-3.99-26-9.324-45.673-16.004-59.002-3.332-7.995-12.32-12-26.994-12h-4.005c-19.994 0-29.99 7.338-29.99 21.997v710c0 14.675 13.328 22.012 40.002 22.012h3.99c26.673 0 40.002-7.337 40.002-22.011v-252c21.338 16.677 43.166 29.334 65.499 38 22.332 8.667 48.165 13.009 77.498 13.009 40.002 0 75.342-10.333 106.006-31 30.663-20.666 54.493-51.008 71.506-91.01 16.997-39.987 25.496-88.336 25.496-144.999 0-52.002-7.841-98.332-23.51-138.992-15.667-40.675-38.152-72.332-67.485-95-29.349-22.669-63.68-34.01-103.01-34.01zM304 66.997c39.33 0 69.672 17.502 90.996 52.506 21.338 35.004 32.008 84.835 32.008 149.493 0 60.011-11.342 106.006-34.01 137.999-22.654 32.008-55.992 48.012-100 48.012-21.995 0-43.823-3.837-65.498-11.51-21.66-7.658-41.164-18.832-58.498-33.49v-274.01c20.009-21.997 41.837-38.994 65.499-50.994C258.174 73.004 281.332 66.997 304 66.997z"
          />
          <glyph
            unicode="c"
            horizAdvX={484}
            d="M297.993-6.007c-82.665 0-144.327 21.338-184.986 64-40.675 42.678-61.005 111.34-61.005 206.005 0 95.336 22.669 163.342 68.006 204.002 45.322 40.66 101.665 61.005 168.998 61.005 38.657 0 73.325-4.678 103.988-14.002 30.678-9.34 46.01-20.009 46.01-32.008 0-8.667-3.837-19.826-11.51-33.491-7.659-13.665-14.491-20.498-20.499-20.498-1.33 0-2.827.49-4.494 1.498-1.666.994-3.836 1.834-6.496 2.492-35.998 16.004-72.331 23.998-109.002 23.998-96.666 0-144.999-63.665-144.999-190.994 0-70.665 11.831-121.673 35.493-152.994C201.175 81.67 240.994 66.003 297 66.003c40.66 0 78.34 8.667 113.007 26l10.99 3.99c6.665 0 14.002-6.496 22.012-19.49 7.994-13.007 12-23.508 12-31.503 0-7.994-7.17-15.836-21.508-23.494-14.338-7.673-33.506-14.17-57.504-19.504-23.999-5.335-50-8.01-78.003-8.01z"
          />
          <glyph
            unicode="d"
            horizAdvX={565}
            d="M246.007-6.007c-39.345 0-73.677 11.342-103.01 34.01-29.333 22.669-51.834 54.325-67.501 95-15.668 40.66-23.494 86.67-23.494 138 0 56.663 8.499 105.164 25.496 145.503 16.998 40.324 40.828 70.834 71.506 91.5 30.663 20.666 66.004 31 105.99 31 29.333 0 55.166-4.342 77.498-13.009 22.348-8.667 44.176-21.323 65.514-38v252c0 14.673 13.33 22.01 39.988 22.01h4.004c26.674 0 40.003-7.337 40.003-22.01v-582c0-43.993 2.002-75.496 5.992-94.497C491.998 34.5 494 24 494 21.997c0-7.995-6.328-13.834-19-17.502C462.328.842 450.665-.993 439.996-.993c-9.324 0-16.157 5.167-20.498 15.5-4.326 10.333-7.658 21.155-9.997 32.497-2.339 11.327-3.837 18.664-4.494 21.996C356.995 19 304-6.006 246.007-6.006zm16.998 73.004c22.668 0 45.826 6.007 69.488 18.006 23.677 12 45.505 28.997 65.514 50.993v274.01c-36.013 29.99-77.009 45.001-123.003 45.001-44.665 0-78.339-16.004-101.007-48.012-22.669-31.993-33.995-77.987-33.995-138 0-64.657 10.67-114.488 31.993-149.492 21.338-35.004 51.665-52.506 91.01-52.506z"
          />
          <glyph
            unicode="e"
            horizAdvX={539}
            d="M299.995-6.007c-83.322 0-144.999 21.675-185.002 65.01-39.987 43.334-59.996 109.658-59.996 199.002 0 88.657 19.673 155.99 59.002 202 39.33 45.994 97.34 68.999 173.996 68.999 68.006 0 119.335-22.164 154.003-66.508C476.666 418.168 494 353.326 494 268.002c0-6.007-4.005-14.338-11.999-25.007-7.994-10.67-14.995-15.989-21.002-15.989h-316c4.005-55.334 18.328-96.009 42.999-122.01 24.671-26 61.662-38.994 111.004-38.994 28.003 0 52.17 3.5 72.5 10.502 20.33 7 37.159 14.001 50.503 21.002 13.33 6.986 21.003 10.486 22.99 10.486 6.665 0 14.17-6.665 22.5-19.994C475.826 74.67 480 64.673 480 57.993c0-7.995-8.33-17.166-24.992-27.5-16.677-10.332-38.84-19-66.508-26-27.667-7-57.168-10.501-88.503-10.501zm105.012 305.009c0 105.333-40.002 157.992-120.007 157.992-85.997 0-132.664-52.66-140.001-157.992h260.008z"
          />
          <glyph
            unicode="f"
            horizAdvX={325}
            d="M147.995-2.002c-26.658 0-40.002 7.337-40.002 21.996v431.008H68.005c-6.68 0-11.678 2.338-15.01 7-3.332 4.662-4.998 12.336-4.998 23.005v12c0 10.653 1.666 18.327 4.998 22.989 3.332 4.677 8.33 7 15.01 7h39.988v95c0 52.675 13.008 88.84 39.009 108.498 26 19.672 55.99 29.501 90.001 29.501 48.67 0 73.004-9.997 73.004-29.99 0-6.665-2.002-16.173-6.007-28.508s-9.003-18.496-14.995-18.496c-3.332 0-8.178.826-14.506 2.492-6.328 1.666-15.836 2.507-28.493 2.507-17.333 0-30.678-4.83-40.002-14.506-9.34-9.66-14.002-29.501-14.002-59.492v-87.006h86.99c7.338 0 12.504-2.323 15.5-7 3.012-4.662 4.51-12.336 4.51-22.99v-12c0-10.669-1.498-18.342-4.51-23.004-2.996-4.662-8.162-7-15.5-7h-86.99V19.992c0-14.659-13.329-21.996-40.002-21.996h-4.005z"
          />
          <glyph
            unicode="g"
            horizAdvX={546}
            d="M253.007-211.996c-135.339 0-203.008 43.656-203.008 130.998 0 28.003 8.163 53.331 24.503 76 16.325 22.669 37.16 39.33 62.503 50l.993 2.002c-25.328 20.666-38 47.997-38 81.992 0 40.675 18.007 72.668 54.004 96.009-28.003 14.001-49.342 33.322-64 57.993C75.326 307.67 68.004 335.336 68.004 366c0 47.34 17.334 86.333 52.002 116.996 34.653 30.678 82.986 46.01 145 46.01 21.323 0 40.995-2.003 58.986-6.007H506c7.337 0 12.504-2.324 15.5-7.001 2.996-4.662 4.494-12.336 4.494-22.99v-11.999c0-10.67-1.498-18.343-4.494-23.005-2.996-4.662-8.163-7-15.5-7-10.669 0-23.494.657-38.504 2.002-14.995 1.33-27.835 2.996-38.49 4.998l-1.008-2.002c12.672-14.002 21.339-27.835 26-41.5 4.663-13.666 7.001-29.838 7.001-48.502 0-47.997-17.165-87.327-51.497-118.005C375.17 217.331 326.67 202 263.997 202c-29.333 0-51.665-4.83-66.997-14.506-15.331-9.66-23.004-25.16-23.004-46.499 0-16.661 5.502-29.501 16.508-38.49 10.99-9.002 24.824-15.33 41.5-19 16.662-3.668 39.667-7.168 69-10.5L330 69.993c51.329-5.992 90.995-18.663 118.998-38 28.003-19.32 42.005-50.32 42.005-92.997 0-48.67-20.177-85.997-60.501-111.998-40.339-26.001-99.51-38.994-177.497-38.994zm10.99 484.004c35.34 0 63.665 7.154 85.004 21.492 21.338 14.338 31.993 38.168 31.993 71.506 0 62.655-38.994 93.99-116.996 93.99-77.33 0-116.003-31.335-116.003-93.99 0-61.999 38.672-92.998 116.003-92.998zm0-415.004c50 0 87.006 6.16 111.004 18.496C399-112.165 411-92.66 411-66.002c0 17.334-6.496 30.83-19.504 40.507-12.993 9.66-28.83 16.493-47.493 20.498-18.663 4.004-43.67 7.658-75.006 11.005-40.003 3.99-67.333 8.33-81.992 12.993C147.674.337 128-27.666 128-64.993c0-28.004 10.501-48.013 31.504-60.012 20.987-11.999 55.823-17.991 104.492-17.991z"
          />
          <glyph
            unicode="h"
            horizAdvX={565}
            d="M132.007-2.002c-26.673 0-40.002 7.337-40.002 21.996v710c0 14.675 13.329 22.012 40.002 22.012h3.99c26.673 0 40.002-7.337 40.002-22.011v-269.99c20.009 19.993 43.167 36.502 69.504 49.494 26.337 12.993 54.157 19.505 83.505 19.505 41.99 0 78.996-11.831 110.989-35.509 32.008-23.662 47.997-65.835 47.997-126.488V19.993c0-14.659-13.33-21.996-39.987-21.996h-4.005c-26.674 0-40.003 7.337-40.003 21.996v339.004c0 30.006-8.667 53.668-26 71.002-17.334 17.334-42.663 26-76 26-24.672 0-47.997-6.832-69.993-20.497-22.012-13.666-40.675-30.174-56.007-49.495V19.993c0-14.659-13.329-21.996-40.002-21.996h-3.99z"
          />
          <glyph
            unicode="i"
            horizAdvX={265}
            d="M130.998-2.002c-26.658 0-40.002 7.337-40.002 21.996v483.01c0 14.658 13.344 21.996 40.002 21.996h4.005c26.658 0 40.002-7.338 40.002-21.996V19.994c0-14.66-13.344-21.996-40.002-21.996h-4.005zM133 594.993c-29.333 0-44.007 7.337-44.007 22.01V677c0 14.674 14.674 21.996 44.007 21.996 29.334 0 44.008-7.322 44.008-21.996v-59.996c0-14.674-14.674-22.011-44.008-22.011z"
          />
          <glyph
            unicode="k"
            horizAdvX={520}
            d="M475 28.003c6.007-6.664 9.003-11.999 9.003-16.004 0-5.334-3.332-9.003-9.997-11.005-6.68-1.988-18.006-2.996-34.01-2.996h-3.99c-14.001 0-25.343.84-34.01 2.506-8.667 1.667-15.332 5.167-19.994 10.502L182.005 247.994H178v-228c0-14.66-13.329-21.996-40.002-21.996h-4.005c-26.658 0-39.987 7.337-39.987 21.996v710c0 14.675 13.33 22.012 39.987 22.012h4.005c26.673 0 40.002-7.337 40.002-22.011V282.998h4.005l190.994 228c7.337 9.34 25.008 14.001 52.996 14.001h4.004c11.327 0 19.994-1.161 26.001-3.5 6.007-2.339 9.003-5.503 9.003-9.493 0-6.007-3.332-12.671-9.996-20.008L268.996 272.007 475 28.003z"
          />
          <glyph
            unicode="l"
            horizAdvX={296}
            d="M211.003-6.007c-33.338 0-63.175 10.18-89.497 30.51-26.337 20.33-39.498 56.16-39.498 107.504v597.988c0 14.674 13.329 22.011 39.987 22.011H126c26.673 0 40.002-7.337 40.002-22.011V140c0-28.003 4.83-47.34 14.49-57.994 9.677-10.67 22.838-16.004 39.514-16.004 12.657 0 22.164.826 28.493 2.492 6.328 1.666 11.173 2.507 14.506 2.507 5.992 0 10.99-6.176 14.995-18.496 4.005-12.335 6.007-21.843 6.007-28.508 0-19.993-24.335-30.005-73.004-30.005z"
          />
          <glyph
            unicode="m"
            horizAdvX={847}
            d="M615.995 529.004c42.005 0 78.675-11.831 110.011-35.508 31.335-23.663 46.988-65.836 46.988-126.488V19.993c0-14.66-13.33-21.997-39.987-21.997h-4.005C702.329-2.003 689 5.335 689 19.993v339.005c0 30.005-8.163 53.667-24.503 71.001C648.172 447.333 624.34 456 593.006 456c-42.677 0-83.673-23.341-123.003-69.993.657-4.677.994-11.342.994-20.009V19.993c0-14.659-13.33-21.996-40.003-21.996h-3.99c-26.673 0-40.002 7.337-40.002 21.996v339.004c0 30.006-8.162 53.668-24.503 71.002-16.325 17.334-40.17 26-71.49 26-24.014 0-46.667-6.664-68.006-19.993-21.339-13.344-39.33-29.67-54.004-49.005V19.993c0-14.66-13.33-21.996-40.003-21.996h-3.99c-26.673 0-40.002 7.337-40.002 21.996v351.004c0 44.007-2.002 75.495-6.007 94.495-3.99 19-5.992 29.501-5.992 31.504 0 8.01 6.328 13.833 19 17.502 12.657 3.668 24.335 5.503 34.989 5.503 9.34 0 16.172-4.83 20.513-14.506 4.326-9.66 7.658-19.826 9.997-30.495 2.324-10.67 3.837-17.67 4.494-21.003 18.664 21.34 41.164 39.162 67.501 53.5 26.338 14.338 54.509 21.507 84.5 21.507 30.005 0 58.176-6.175 84.498-18.511 26.337-12.32 45.842-33.827 58.498-64.49 20.666 23.998 44.833 43.824 72.5 59.492 27.667 15.667 56.51 23.509 86.501 23.509z"
          />
          <glyph
            unicode="n"
            horizAdvX={556}
            d="M125.999-2.002c-26.673 0-40.002 7.337-40.002 21.996v351.004c0 44.007-2.003 75.495-5.992 94.495-4.005 19-6.008 29.501-6.008 31.504 0 8.01 6.329 13.833 19 17.502 12.672 3.668 24.335 5.503 35.004 5.503 9.325 0 16.173-4.83 20.498-14.506 4.342-9.66 7.674-19.826 9.997-30.495 2.339-10.67 3.837-17.67 4.51-21.002 21.323 23.34 45.658 41.668 72.988 54.997 27.346 13.344 55.013 20.009 83.001 20.009 50.672 0 90.506-14.338 119.503-42.998 28.997-28.676 43.503-68.342 43.503-118.998V19.995c0-14.66-13.329-21.996-40.002-21.996h-4.005c-26.658 0-39.987 7.337-39.987 21.996v339.004c0 30.662-8.835 54.508-26.506 71.506-17.67 16.997-42.83 25.496-75.495 25.496-22.669 0-45.17-6.328-67.501-19-22.333-12.672-41.837-29.67-58.498-50.993V19.994c0-14.659-13.345-21.996-40.003-21.996H126z"
          />
          <glyph
            unicode="o"
            horizAdvX={569}
            d="M287.003-6.007c-75.343 0-132.832 22.668-172.498 68.005C74.839 107.336 54.998 173.002 54.998 259c0 86.67 20.009 153.33 59.996 199.997 40.003 46.667 97.339 70.009 172.01 70.009 73.324 0 129.5-23.173 168.492-69.504 39.009-46.33 58.498-113.175 58.498-200.501 0-86.67-19.168-152.505-57.49-197.505-38.335-45-94.831-67.501-169.501-67.501zm-2.996 72.01c48.654 0 84.667 15.836 107.993 47.493 23.341 31.671 35.004 80.83 35.004 147.506 0 66.66-11.663 116.003-35.004 147.995-23.326 32.008-59.339 47.996-107.993 47.996-48.67 0-84.346-15.82-106.999-47.492-22.669-31.672-34.01-81.167-34.01-148.5 0-66.676 11.341-115.834 34.01-147.506 22.653-31.656 58.33-47.492 106.999-47.492z"
          />
          <glyph
            unicode="r"
            horizAdvX={365}
            d="M130.004-2.002c-26.673 0-40.002 7.337-40.002 21.996v351.004c0 44.007-2.003 75.495-6.008 94.495-3.99 19-5.992 29.501-5.992 31.504 0 8.01 6.329 13.833 19 17.502 12.672 3.668 24.335 5.503 35.004 5.503 9.325 0 16.157-4.83 20.498-14.506 4.326-9.66 7.659-19.826 9.997-30.495 2.339-10.67 3.837-17.67 4.494-21.002 35.34 49.999 73.998 75.006 116.003 75.006 19.337 0 33.843-3.164 43.503-9.508 9.66-6.328 14.506-16.157 14.506-29.5 0-11.327-2.002-23.159-6.007-35.494-4.005-12.336-8.667-18.496-14.002-18.496-3.332 0-9.828 1.82-19.504 5.488-9.66 3.668-21.492 5.503-35.493 5.503-17.334 0-33.995-5.824-50-17.502-16.003-11.663-30.005-26.17-42.004-43.488V19.994c0-14.659-13.33-21.996-40.003-21.996h-3.99z"
          />
          <glyph
            unicode="s"
            horizAdvX={413}
            d="M197.995-9.003c-28.66 0-55.166 3.5-79.5 10.501-24.335 7-43.656 15.5-57.994 25.496-14.338 10.012-21.507 19-21.507 27.01 0 6.665 4.173 16.661 12.504 29.99 8.33 13.345 15.836 20.01 22.5 20.01 1.33 0 7.673-3.67 19-11.006 11.342-7.337 25.496-14.002 42.51-19.994 16.997-6.007 35.829-9.003 56.495-9.003 31.993 0 56.327 6.328 73.004 19 16.661 12.672 24.992 31 24.992 54.998 0 23.998-8.163 42.34-24.503 54.997-16.325 12.672-41.5 24.671-75.495 35.998-28.66 9.34-52.675 19.504-71.996 30.51-19.336 10.99-35.34 26.658-48.012 47.003-12.656 20.33-19 46.499-19 78.492 0 28.003 7.17 52.995 21.507 75.006 14.338 21.996 33.995 39.162 59.003 51.497 24.992 12.336 53.5 18.496 85.492 18.496 34.01 0 64.505-4.326 91.5-12.993 27.01-8.667 40.507-18.679 40.507-30.005 0-8.667-3.837-19.826-11.495-33.507-7.673-13.665-14.506-20.498-20.513-20.498-1.988.673-10.823 5.167-26.49 13.498-15.668 8.346-37.175 12.503-64.506 12.503-26 0-47.324-5.992-64-17.991-16.662-12-24.993-28.34-24.993-49.006 0-19.336 5.488-34.331 16.494-45 11.005-10.67 31.503-21.003 61.509-31 51.986-17.334 91.652-38.336 118.998-62.992 27.33-24.67 40.996-60.011 40.996-106.006 0-29.333-7.842-55.838-23.51-79.5-15.652-23.662-36.99-42.341-63.985-56.007C260.497-2.17 230.66-9.004 197.994-9.004z"
          />
          <glyph
            unicode="t"
            horizAdvX={342}
            d="M235.995-6.007c-33.323 0-63.16 10.18-89.497 30.51C120.16 44.833 107 80.663 107 132.007v318.995H57c-6.664 0-11.662 2.339-14.995 7-3.332 4.663-4.998 12.336-4.998 23.005v12c0 10.654 1.666 18.327 4.998 22.99 3.333 4.677 8.331 7 14.996 7H107l11.999 104.997c2.002 14.674 12.672 22.011 32.008 22.011h9.997c19.993 0 29.99-7.337 29.99-22.01V522.996h84.01c7.337 0 12.503-2.323 15.5-7 2.995-4.663 4.493-12.336 4.493-22.99v-12c0-10.669-1.498-18.342-4.494-23.004-2.995-4.662-8.162-7.001-15.5-7.001h-84.009V140c0-28.003 4.846-47.34 14.506-57.994 9.66-10.67 22.837-16.004 39.498-16.004 12.672 0 22.164.826 28.508 2.492 6.328 1.666 11.158 2.507 14.49 2.507 6.008 0 11.006-6.176 15.011-18.496C306.997 40.171 309 30.663 309 23.998c0-19.993-24.335-30.005-73.004-30.005z"
          />
          <glyph
            unicode="u"
            horizAdvX={556}
            d="M237.997-6.007c-50.657 0-90.49 14.338-119.503 43.013-28.997 28.66-43.488 68.327-43.488 118.998v347c0 14.658 13.33 21.995 39.988 21.995h4.004c26.674 0 40.003-7.337 40.003-21.996V164c0-30.662 8.835-54.493 26.505-71.506 17.655-16.997 42.83-25.496 75.496-25.496 22.668 0 45.169 6.343 67.501 19 22.332 12.672 41.837 29.67 58.498 51.008v366c0 14.658 13.33 21.995 40.002 21.995h3.99c26.673 0 40.002-7.337 40.002-21.996V147.996c0-43.992 2.003-75.496 6.008-94.496 4.004-19 5.992-29.5 5.992-31.503 0-7.995-6.329-13.834-19-17.502C451.338.84 439.66-.993 429.005-.993c-9.339 0-16.171 5.167-20.513 15.5-4.325 10.333-7.658 21.155-9.996 32.497-2.324 11.327-3.837 18.664-4.494 21.996-21.339-23.326-45.674-41.669-73.004-54.998C293.667.673 266-6.006 237.997-6.006z"
          />
          <glyph
            unicode="w"
            horizAdvX={746}
            d="M208.007-2.002c-24.014 0-37.343 5.334-40.002 16.004L35.998 487.994c-3.333 10.012-4.999 16.676-4.999 20.008C31 519.33 46.331 525 76.994 525c24.013 0 37.342-5.335 40.002-16.004l84.01-319.989c1.987-6.007 4.326-16.172 7-30.51 2.66-14.323 4.663-25.16 5.992-32.497l9.997-52.002H228c10.67 59.339 18.327 97.996 23.005 116.003l83 318.002c2.66 11.327 15.669 16.998 38.994 16.998H380c23.326 0 36.334-5.671 38.994-16.998l84.01-318.002c7.337-29.333 12.824-54.83 16.493-76.504 3.668-21.66 6.175-35.494 7.505-41.5h2.002L536.005 114c7.322 38 12.336 62.671 14.995 73.998l83.995 320.998c2.675 10.67 16.34 16.004 41.011 16.004 26.658 0 39.987-5.671 39.987-16.998 0-3.332-.993-7.994-2.996-14.002L579.003 14.002C576.328 3.333 563-2.002 539.001-2.002h-30.006c-23.326 0-36.334 5.335-38.993 16.004l-80.005 316.993c-2.003 8.01-6.665 34.332-14.002 79.012h-2.002c-7.322-42.005-12-68.006-13.987-78.003L278 14.002c-2.66-10.67-16.004-16.004-40.002-16.004h-29.99z"
          />
        </font>
      </defs>
      <g id="prefix__Layer_x0020_1">
        <path
          className="prefix__fil0"
          d="M10542.69 6028.19H462.36c-62.13-.06-112.5-50.44-112.56-112.57V112.55C349.86 50.43 400.23.05 462.36 0h10080.32c62.13.06 112.51 50.43 112.56 112.56v5803.07c-.06 62.12-50.43 112.5-112.56 112.55zM462.36 25.02c-48.32.06-87.49 39.22-87.54 87.54v5803.07c.05 48.32 39.21 87.49 87.54 87.54l10080.33.01c48.32-.06 87.49-39.23 87.54-87.55V112.56c-.05-48.32-39.22-87.49-87.54-87.54H462.36z"
        />
        <path
          d="M8483.88 5569.04H2238.4c-115.07-.11-208.38-93.43-208.49-208.51V1509.6c.1-115.05 93.42-208.37 208.5-208.48l6245.46.01c115.06.09 208.38 93.41 208.48 208.49v3850.93c-.1 115.06-93.42 208.37-208.5 208.48l.02.01z"
          fill="#e6e6e6"
          fillRule="nonzero"
        />
        <path
          className="prefix__fil2"
          d="M472.01 4272.5h-.09c-19.08 0-36.88-9.68-47.25-25.69l-69.46-107.32c-5.87-9.1-9-19.73-9-30.56 0-19.09 9.67-36.9 25.68-47.29l894.38-578.9c9.1-5.87 19.73-9 30.56-9 19.08 0 36.89 9.68 47.28 25.68l69.46 107.32c5.9 9.1 9.04 19.73 9.04 30.58 0 19.09-9.69 36.89-25.71 47.26l-894.38 578.9a55.89 55.89 0 01-30.45 9.02h-.05z"
        />
        <path
          className="prefix__fil3"
          d="M499.88 4256.32l373.87-208.76c16.44-9.17 26.64-26.55 26.64-45.37 0-8.04-1.87-15.97-5.44-23.16l-86.62-174.02a51.985 51.985 0 00-46.52-28.81 52 52 0 00-31.74 10.82l-356.18 274.72a52.073 52.073 0 00-23.7 43.66c0 9.99 2.88 19.78 8.29 28.18l69.47 107.33a52.062 52.062 0 0071.93 15.4z"
        />
        <circle className="prefix__fil2" cx={723.31} cy={253.14} r={87.55} />
        <circle className="prefix__fil2" cx={973.44} cy={253.14} r={87.55} />
        <circle className="prefix__fil2" cx={1223.57} cy={253.14} r={87.55} />
        <path
          className="prefix__fil0"
          d="M362.3 462.75h10280.44v62.53H362.3zM2689.11 1031.32c-101.52.09-183.85 82.42-183.94 183.95V5066.2c.09 101.52 82.42 183.85 183.95 183.94l6245.47.03c101.53-.12 183.86-82.45 183.96-183.98V1215.26c-.09-101.52-82.43-183.85-183.96-183.94H2689.11z"
        />
        <circle className="prefix__fil2" cx={463.51} cy={3122.06} r={463.51} />
        <path
          className="prefix__fil4"
          d="M390.48 6009.05H262.65c-31.07-.03-56.26-25.23-56.29-56.3V4887.37c.03-31.07 25.23-56.27 56.3-56.3h127.83c31.07.02 56.27 25.22 56.3 56.29v1065.39c-.02 31.07-25.22 56.26-56.29 56.29zM643.3 6009.05H515.47c-31.07-.03-56.27-25.23-56.29-56.3V4887.37c.03-31.07 25.23-56.27 56.3-56.3H643.3c31.07.02 56.27 25.22 56.3 56.29l-.01 1065.39c-.02 31.07-25.22 56.26-56.29 56.29z"
        />
        <path
          className="prefix__fil3"
          d="M748.65 5008.3h-580.8c-31.07-.03-56.26-25.23-56.29-56.31v-979.5c.16-171.5 139.23-310.57 310.74-310.72h326.36c31.07.02 56.27 25.22 56.3 56.29V4952c-.02 31.07-25.22 56.26-56.3 56.29v.01z"
        />
        <path
          className="prefix__fil4"
          d="M868.1 3250.84l-.48-17.32c32.22-.9 60.63-2.92 81.98-18.52a53.28 53.28 0 0020.63-39.22c.06-.81.09-1.63.09-2.44 0-8.63-3.67-16.86-10.08-22.63-14.17-11.98-36.97-8.1-53.59-.48l-14.33 6.58 27.48-200.85 17.16 2.35-23.38 170.85c22.59-6.64 43.51-3.79 57.83 8.32a47.378 47.378 0 0116.12 38.9c-1.01 20.76-11.17 40.02-27.71 52.6-27.43 20.05-63.08 21.04-91.72 21.85zM727.36 2973.06h93.29v17.32h-93.29z"
        />
        <path
          className="prefix__fil2"
          d="M999.81 4931.87c-20.85-.02-40.01-11.54-49.8-29.94l-499.42-941.08a56.368 56.368 0 0123.34-76.11l112.91-59.92c8.12-4.3 17.17-6.55 26.36-6.55 20.83 0 39.98 11.5 49.76 29.88l499.41 941.08a56.469 56.469 0 016.54 26.36c0 20.83-11.5 39.97-29.88 49.76l-112.92 59.92a56.06 56.06 0 01-26.31 6.6z"
        />
        <path
          className="prefix__fil3"
          d="M414.65 3958.86l218.96 390.52a51.977 51.977 0 0047.39 30.64c6.51 0 12.98-1.23 19.04-3.62l180.86-71.24c19.84-7.81 32.91-27 32.91-48.35 0-9.95-2.86-19.69-8.22-28.06l-242.91-395.91a52.059 52.059 0 00-45.94-27.59c-8.47 0-16.8 2.08-24.29 6.02l-156.24 77.25c-16.99 9.03-27.61 26.72-27.61 45.96 0 8.49 2.08 16.86 6.05 24.36z"
        />
        <path
          className="prefix__fil4"
          d="M738.65 2783.16c55.91-5.2 102.71-42.22 127.53-91.76a187.965 187.965 0 0019.45-83.23c0-28.32-6.44-56.35-18.76-81.83-26.32-53.95-70.33-97.37-124.62-123.02-63.18-30.6-134.53-39.08-204.03-36.27-130.96 5.29-275.25 42.06-355.75 153.72-31.15 41.62-48.01 92.33-48.01 144.36 0 10.83.73 21.7 2.17 32.41 10.19 65.76 50.21 122.71 92.95 171.8 44.79 51.45 96.47 97.79 131.87 156.73 30.63 50.99 47.1 115.22 26.61 173.09a124.22 124.22 0 01-21.42 37.7c-10.59 12.78 7.69 31.26 18.37 18.37 36.86-44.48 44.31-103.19 32.71-158.54-12.78-60.93-49.64-112.44-89.95-158.46-78.43-89.54-195.24-187.31-162.27-321.08 15.45-57.95 50.72-108.77 99.6-143.55 52.43-38.01 114.67-58.92 177.92-70.28 121.04-21.73 267.61-19.46 359.06 74.85 38 39.2 64.15 93.96 57.14 149.54-6.31 50.12-38.31 99.28-85.1 120.18-11.23 5-23.22 8.14-35.46 9.28-16.51 1.53-16.67 27.53 0 25.98z"
        />
        <text x={3088.73} y={2324.98} className="prefix__fil5 prefix__fnt0">
          {"Situs sekolahmadani saat ini terintegrasi"}
        </text>
        <text x={3837.09} y={2732.87} className="prefix__fil5 prefix__fnt0">
          {"dengan website elfatih.sch.id."}
        </text>
        <text x={3798.75} y={3140.75} className="prefix__fil5 prefix__fnt0">
          {"Anda akan dialihkan otomatis"}
        </text>
        <text x={3674.59} y={3548.64} className="prefix__fil5 prefix__fnt0">
          {"ke situs tersebut dalam 15 detik"}
        </text>
        <text x={3848.38} y={3956.52} className="prefix__fil5 prefix__fnt0">
          {"atau klik tombol di bawah ini"}
        </text>
      </g>
    </svg>
  );
}

const Memoaaa = React.memo(aaa);
export default Memoaaa;
